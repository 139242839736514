export default [{
    title: 'Übersicht',
    route: 'dashboard',
    icon: 'BarChartIcon',
  },
  {
    title: 'Kunde',
    route: 'customers-list',
    icon: 'UserIcon',
  },
  {
    title: 'Behandlung',
    route: 'treatments-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Mitarbeiter',
    route: 'employees-list',
    icon: 'SmileIcon',
  },
]